import * as React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { config } from 'utils/sanity'
import clsx from 'clsx'

const SanityBlock = ({ className, ...props }) => (
  <BlockContent {...config}
    className={clsx(className, 'richtext')}
    renderContainerOnSingleChild
    {...props}
  />
)

export default SanityBlock
