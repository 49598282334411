import * as React from 'react'
import * as css from './YouTubeEmbed.module.css'

const YouTubeEmbed = ({ id, title }) => (
  <div className={css.root}>
    <iframe
      className={css.iframe}
      src={`https://youtube.com/embed/${id}`}
      frameborder="0"
      title={title}
      loading="lazy"
    ></iframe>
  </div>
)

export default YouTubeEmbed
