import * as React from 'react'
import * as css from './RequestQuoteCTA.module.css'
import newtab from 'utils/newtab'
import clsx from 'clsx'
import { FaCheck } from 'react-icons/fa'

const RequestQuoteCTA = ({ className }) => {
  return (
    <div className={clsx(css.root, 'sticky')}>
      <h2 className="h3">Get a complimentary solar quote</h2>
      <ul>
        <li><FaCheck /><p>Custom Solar Kit Recommendation</p></li>
        <li><FaCheck /><p>Custom Solar Panel Layout</p></li>
        <li><FaCheck /><p>Total Project Cost</p></li>
        <li><FaCheck /><p>Total Savings</p></li>
      </ul>
      <a className="action" href="https://www.gogreensolar.com/pages/request-quote" {...newtab}>
        Request a quote
      </a>
    </div>
  )
}

export default RequestQuoteCTA
