import * as React from 'react'
import * as css from './Article.module.css'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Richtext from 'components/common/Richtext'
import { graphql, useStaticQuery } from 'gatsby'
import CategoryAndDate from './CategoryAndDate'

const Article = ({ post }) => {
  const { logo } = useStaticQuery(query)

  return (
    <article className={css.root}>
      <div className={css.inner}>
        <h1 className="h1">{post.title}</h1>

        <CategoryAndDate {...post} />

        {post.featuredImg && (
          <GatsbyImage
            image={getImage(post.featuredImg.image.asset)}
            alt={post.featuredImg.alt || post.title}
          />
        )}

        <Richtext className={css.body} blocks={post._rawBody} />

        <GatsbyImage
          className={css.logo}
          image={getImage(logo)}
          alt=""
          draggable="false"
        />
      </div>
    </article>
  )
}

export default Article

const query = graphql`query Article {
  logo: file(relativePath: {eq: "images/gogreensolar-logo-sm.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE)
    }
  }
}`
