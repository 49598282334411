import * as React from 'react'
import * as css from './ImageWithCaption.module.css'
import RTImg from './RTImg'
import newtab from 'utils/newtab'

const Image = ({ image, alt, caption }) => <>
  <RTImg image={image} alt={alt} />
  {caption && <figcaption className={css.caption}>{caption}</figcaption>}
</>

const ImageWithCaption = ({ url, ...props }) => (
  <figure>
    {!!url
      ? <a className={css.link} href={url} {...newtab}>
        <Image {...props} />
      </a>
      : <Image {...props} />
    }
  </figure>
)

export default ImageWithCaption
