import * as React from 'react'
import * as css from './post.module.css'
import { graphql } from 'gatsby'
import DefaultLayout from 'layouts/Default'
import PostSidebar from 'components/sidebar/PostSidebar'
import Article from 'components/common/Article'
import RequestQuoteCTA from 'components/cta/RequestQuoteCTA'
import ReadNext from 'components/common/ReadNext'
import useSiteMetadata from 'hooks/useSiteMetadata'

const PostTemplate = ({ data }) => {
  const post = data.sanityPost
  const { siteUrl } = useSiteMetadata()

  return (
    <DefaultLayout {...post.seo} className="container side-padding section-padding">
      <div className={css.grid}>
        <aside>
          <PostSidebar shareUrl={`${ siteUrl }/${ post.seo.slug.current }`} />
        </aside>

        <Article post={post} />

        <aside>
          <RequestQuoteCTA />
        </aside>
      </div>

      <ReadNext current={post} />
    </DefaultLayout>
  )
}

export default PostTemplate

export const query = graphql`query PostTemplate($id: String) {
  sanityPost(id: {eq: $id}) {
    title
    category { title }
    publishDate
    formattedPublishDate: publishDate(formatString: "MMMM D, y")
    featuredImg {
      image { asset { gatsbyImageData(placeholder: NONE) } }
      alt
    }
    _rawBody
    seo {
      title
      description
      slug { current }
    }
  }
}`
