import * as React from 'react'
import * as css from './CTAModule.module.css'
import RTImg from './RTImg'
import SanityBlock from './SanityBlock'
import CTAList from './CTAList'

const CTAModule = ({ title, content, ctas, image }) => {
  return (
    <div className={css.root}>
      <RTImg className={css.img} image={image} draggable={false} />

      <div className={css.txt}>
        <h2 className="h2">{title}</h2>
        {!!content?.length && <SanityBlock blocks={content} />}
        <CTAList className={css.ctas} ctas={ctas} />
      </div>
    </div>
  )
}

export default CTAModule
