import * as React from 'react'
import * as css from './CTAList.module.css'
import clsx from 'clsx'

const CTAList = ({ ctas, className }) => {
	if (!ctas?.length) return null

	return (
		<div className={clsx(css.root, className)}>
			{ctas?.map((cta, key) => !!cta?.url && (
				<a className="action" href={cta.url} key={key}>
					{cta.label}
				</a>
			))}
		</div>
	)
}

export default CTAList
