import * as React from 'react'
import imageUrlBuilder from '@sanity/image-url'
import { config } from 'utils/sanity'

const RTImg = ({ image, alt = '', height = 700, ...props }) => {
  if (!image) return null

  const src = imageUrlBuilder(config).image(image)
    .height(height)
    .fit('max')
    .auto('format')
    .url()

  const { w, h } = src.match(/-(?<w>\d+)x(?<h>\d+)\.(jpg|jpeg|png|gif)/).groups

  return (
    <img
      src={src}
      alt={alt}
      width={h >= height ? parseInt(w / h * height) : w}
      height={h >= height ? height : h}
      loading="lazy"
      {...props}
    />
  )
}

export default RTImg
