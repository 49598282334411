import * as React from 'react'
import * as css from './PostSidebar.module.css'
import { Link } from 'gatsby'
import { HiArrowNarrowLeft as Left } from 'react-icons/hi'
import { EmailShareButton, FacebookShareButton, TwitterShareButton } from 'react-share'
import { IoPaperPlane } from 'react-icons/io5'
import { FaFacebookF, FaTwitter } from 'react-icons/fa'

const PostSidebar = ({ shareUrl }) => (
  <div className={`${css.root} sticky`}>
    <div className={css.back}>
      <Link to="/">
        <Left className="h2" />
        <span className="tablet">Back</span>
      </Link>
    </div>

    <EmailShareButton className={css.icon} url={shareUrl}>
      <IoPaperPlane className="h3" />
    </EmailShareButton>

    <FacebookShareButton className={css.icon} url={shareUrl}>
      <FaFacebookF className="h3" />
    </FacebookShareButton>

    <TwitterShareButton className={css.icon} url={shareUrl}>
      <FaTwitter className="h3" />
    </TwitterShareButton>
  </div>
)

export default PostSidebar
