import * as React from 'react'
import * as css from './ReadNext.module.css'
import { GlobalContext } from 'components/Context'
import PostPreview from 'components/common/PostPreview'

const ReadNext = ({ current }) => {
  const { allPosts } = React.useContext(GlobalContext)

  const posts = allPosts.filter(post => post.seo.slug.current !== current.seo.slug.current)

  return (
    <section className={css.root}>
      <h2 className="h2">Read this next</h2>

      <ul className={css.list}>
        {posts.map((post, key) => (
          (key < 3) && <li key={key}>
            <PostPreview post={post} />
          </li>
        ))}
      </ul>
    </section>
  )
}

export default ReadNext
