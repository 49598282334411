import * as React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import slugify from 'slugify'
import CTAModule from 'components/common/CTAModule'
import ImageWithCaption from 'components/common/ImageWithCaption'
import YouTubeEmbed from 'components/common/YouTubeEmbed'
import RTTable from 'components/common/RTTable'
import newtab from 'utils/newtab'
import SanityBlock from './SanityBlock'

const serializers = {
	types: {
		block: (props) => {
			const { style } = props.node

			switch (style) {
				case 'h1':
				case 'h2':
				case 'h3':
				case 'h4':
				case 'h5':
				case 'h6':
					return React.createElement(
						style,
						{
							className: style,
							id:
								typeof props.children[0] === 'string'
									? slugify(props.children[0], { lower: true })
									: null,
						},
						props.children,
					)

				default:
					return BlockContent.defaultSerializers.types.block(props)
			}
		},

		'cta.module': ({ node }) => <CTAModule {...node} />,
		imageWithCaption: ({ node }) => <ImageWithCaption {...node} />,
		youtubeEmbed: ({ node }) => <YouTubeEmbed {...node} />,
		table: ({ node }) => <RTTable {...node} />,
	},

	marks: {
		link: (props) => {
			const { href } = props.mark
			const external = !href?.includes('blog.gogreensolar.com') && newtab

			return (
				<a href={href} className="link" {...external}>
					{props.children}
				</a>
			)
		},
	},
}

const Richtext = ({ blocks, className }) => (
	<SanityBlock
		blocks={blocks}
		serializers={serializers}
		className={className}
	/>
)

export default Richtext
