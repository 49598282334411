import * as React from 'react'
import * as css from './RTTable.module.css'

const Table = ({ rows }) => {
	return (
		<figure>
			<div className={css.wrapper}>
				<table>
					<tbody>
						{rows.map(({ cells }, i) => (
							<tr key={i}>
								{cells.map((cell, j) => (
									<td key={j}>{cell}</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</figure>
	)
}

export default Table
